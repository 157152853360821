<template>
  <div class="cms-element-bs-image-circle" :class="classes">
    <cms-util-link
      v-if="media"
      :url="imageLink"
      class="cms-element-bs-image-circle__circle"
      :style="mediaStyle"
      wrapped="div"
    >
      <base-image
        :media="media"
        loading="lazy"
        :init-width="mediaSizeNumber"
        :width="mediaSizeNumber"
        :height="mediaSizeNumber"
      />
    </cms-util-link>
    <cms-element-text
      v-if="text"
      :content="content"
      :class="textClasses"
    ></cms-element-text>
  </div>
</template>

<script>
import CmsElementText from '~/components/cms/elements/CmsElementText'
import { getCmsLink, getCmsSlotConfigProperty } from '~/helpers'
import BaseImage from '~/components/base/image/BaseImage'

export default {
  name: 'CmsElementBsImageCircle',
  components: { BaseImage, CmsElementText },
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    text() {
      return this.content.data?.content
    },
    media() {
      return this.content.data?.media
    },
    imageLink() {
      return getCmsLink(this.content)
    },
    textClasses() {
      return getCmsSlotConfigProperty(this.content, 'textCss')
    },
    mediaSize() {
      return getCmsSlotConfigProperty(this.content, 'mediaSize') || undefined
    },
    mediaStyle() {
      return {
        height: this.mediaSize,
        width: this.mediaSize,
      }
    },
    classes() {
      return [getCmsSlotConfigProperty(this.content, 'elementCss')]
    },
    mediaSizeNumber() {
      return this.mediaSize && parseInt(this.mediaSize.replace('px', '').trim())
    },
  },
}
</script>
